<template>
  <b-pagination
    v-model="page"
    :total-rows="total"
    :per-page="perPage"
    :limit="25"
    aria-controls="table"
    @change="changePage"
  />
</template>

<script>
import { BPagination } from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BPagination,
  },
  props: {
    total: {
      type: Number,
      required: false,
      default: 15,
    },
    store: {
      type: String,
      required: false,
      default: null,
    },
    auxFilters: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      page: 1,
    }
  },
  computed: {
    ...mapGetters({
      filters: 'filters/filters',
      perPage: 'pagination/perPage',
      currentPage: 'pagination/page',
    }),
  },
  watch: {
    currentPage() {
      this.setPage()
    },
  },
  mounted() {
    this.setPage()
  },
  methods: {
    setPage() {
      this.page = this.currentPage
    },
    async changePage(page) {
      this.$emit('loading', true)
      await this.$store.dispatch('pagination/savePage', page)
      if (this.store && this.store !== '-') {
        await this.$store.dispatch(this.store, this.auxFilters ?? this.filters)
      }
      this.$emit('loading', false)
      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 100)
    },
  },
}
</script>
